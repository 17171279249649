import { useRouter } from 'next/router';
import Link from 'next/link';
import { routes } from '@/constants/routes';

export default function Custom404() {
  const router = useRouter();

  return (
    <div>
      <p className="error">
        <strong>요청하신 페이지를 찾을 수 없습니다.</strong>
        페이지의 주소가 변경 혹은 삭제되어 요청하신 페이지를 찾을 수 없습니다.
        <br />
        이전 버튼을 누르시면 서비스를 계속 이용하실 수 있습니다.
      </p>
      <div className="btn-area">
        <a href="#" className="btn-type2" onClick={() => router.back()}>
          이전 페이지
        </a>
        <Link href={routes.home} className="btn-type2 on">
          메인
        </Link>
      </div>
    </div>
  );
}
